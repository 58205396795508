import { device } from 'src/styles/breakpoints'
import { rounded } from 'src/styles/rounded'
import styled from 'styled-components'

export const Benefits = styled.section`
  .icon-circle img {
    max-width: 29px;
    background-color: #FFF2E7;
    border-radius: ${rounded['5']};
  }

  .card-image {
    max-width: 309px;
    @media ${device.desktopLG} {
      max-width: 456px;
    }
  }

  ul {
    border-bottom: 1px solid #F5F6FA;
    list-style: none;
    display: flex;
    overflow: auto hidden;
    -webkit-box-pack: start !important;
    justify-content: start !important;

    @media ${device.desktopLG} {
      border-bottom: 1px solid #F5F6FA;
      display: flex !important;
      justify-content: space-evenly !important;
    }
  }

  li {
    button {
      font-family: "Sora", Helvetica, sans-serif;

      padding: 0 4px 8px;
      @media ${device.tablet} {
        padding: 0 35px 10px;
      }
      @media ${device.desktopLG} {
        padding: 0 45px 12px;;
      }
      @media ${device.desktopXL} {
        padding: 0 45px 12px;;
      }
    }
  }

  .button-teste-ab {
    min-width: 309px;
    height: 32px !important;
    border-radius: 8px;
    @media ${device.tablet} {
      min-width: 264px;
    }
  }
`
export const Button = styled.div`
  button, a {
    text-transform: none;
    @media ${device.tablet} {
      max-width: 696px!important;
      margin: 0 auto;
    }
    @media ${device.desktopLG} {
      max-width: 541px!important;
    }
    @media ${device.desktopXL} {
      max-width: 631px!important;
    }
  }
`
